<template>
  <div>
    <ejs-dialog
      ref="payCancelPopup"
      :header="`정산 취소`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="402"
      :isModal="true"
      :close="closePayCancelPopup"
    >
      <div class="window payCancel">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul
                            class="data-content"
                            v-if="popupData.payDiv === 'CARD'"
                          >
                            <li class="field">
                              <div class="title">거래종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">신용취소</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">영업일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ this.popupData.payDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">카드번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCardInfo.cardNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">카드회사</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCardInfo.purcName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">할부개월</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCardInfo.divideTerm }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">승인번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCardInfo.approvalNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>

                          <ul
                              class="data-content"
                              v-if="popupData.payDiv === 'EASY_PAY'"
                          >
                            <li class="field">
                              <div class="title">거래종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">신용취소</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">영업일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ this.popupData.payDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">카드번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCardInfo.cardNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">카드회사</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCardInfo.purcName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">할부개월</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCardInfo.divideTerm }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">승인번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCardInfo.approvalNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>

                          <ul
                            class="data-content"
                            v-if="
                              popupData.salesDiv !== 'DEPOSIT' &&
                              (popupData.payDiv === 'CASH' ||
                                popupData.payDiv === 'TRANS')
                            "
                          >
                            <li class="field">
                              <div class="title">거래종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{
                                      commonCodesGetComName(
                                        "PAY_DIV",
                                        popupData.payDiv
                                      )
                                    }}취소
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">영업일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ this.popupData.payDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">증빙번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCashInfo.proofNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">증빙번호 확인</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <input-text
                                      :disabled="
                                        payCashInfo.approvalDivision === 'T_OK' ||
                                        payCashInfo.proofKind === 'VOL' ||
                                        cardProof
                                      "
                                      v-model="proofNo"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              class="field"
                              v-if="popupData.cashId && van === 'NICE'"
                            >
                              <div class="title">카드 증빙</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <input
                                      type="checkbox"
                                      :disabled="
                                        payCashInfo.proofKind === 'VOL'
                                      "
                                      v-model="cardProof"
                                      class="e-input"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">거래구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ proofKindName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">승인번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCashInfo.approvalNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">취소사유</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <ejs-dropdownlist
                                      v-model="payCashProofCancelCode"
                                      :dataSource="proofCancelCodeList"
                                      :allowFiltering="false"
                                      :fields="{
                                        text: 'comName',
                                        value: 'comCode',
                                      }"
                                      cssClass="lookup-condition-dropdown"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul
                            class="data-content"
                            v-if="popupData.payDiv === 'CREDIT'"
                          >
                            <li class="field">
                              <div class="title">거래종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">미수취소</li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">영업일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ this.popupData.payDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul
                            class="data-content"
                            v-if="
                              ['DEPOSIT','PRECARD','GIFT','GROUP_GIFT','CREDIT_DEF','SELF','POINT','TICKET'].includes(popupData.payDiv)
                            "
                          >
                            <li class="field">
                              <div class="title">거래종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{
                                      commonCodesGetComName(
                                        "PAY_DIV",
                                        popupData.payDiv
                                      )
                                    }}취소
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">영업일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ this.popupData.payDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">증빙번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCashInfo.proofNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">증빙번호 확인</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <input-text
                                      :disabled="
                                        payCashInfo.proofKind === 'VOL' ||
                                        cardProof
                                      "
                                      v-model="proofNo"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              class="field"
                              v-if="popupData.cashId && van === 'NICE'"
                            >
                              <div class="title">카드 증빙</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <input
                                      type="checkbox"
                                      :disabled="
                                        payCashInfo.proofKind === 'VOL'
                                      "
                                      v-model="cardProof"
                                      class="e-input"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">거래구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ proofKindName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">승인번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ payCashInfo.approvalNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="popupData.cashId">
                              <div class="title">취소사유</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <ejs-dropdownlist
                                      v-model="payCashProofCancelCode"
                                      :dataSource="proofCancelCodeList"
                                      :allowFiltering="false"
                                      :fields="{
                                        text: 'comName',
                                        value: 'comCode',
                                      }"
                                      cssClass="lookup-condition-dropdown"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul
                            class="data-content"
                            v-if="
                              popupData.payDiv === 'RETURN' ||
                              popupData.payDiv === 'YEAR' ||
                              popupData.payDiv === 'ETC'
                            "
                          >
                            <li class="field">
                              <div class="title">사용구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{
                                      commonCodesGetComName(
                                        "DEPOSIT_USE_DIV",
                                        popupData.payDiv
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">사용일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul
                            class="data-content"
                            v-if="
                              popupData.salesDiv === 'DEPOSIT' &&
                              (popupData.payDiv === 'CASH' ||
                                popupData.payDiv === 'PROMTN' ||
                                popupData.payDiv === 'TRANS')
                            "
                          >
                            <li class="field">
                              <div class="title">입금구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{
                                      commonCodesGetComName(
                                        "INCOM_DIV",
                                        popupData.payDiv
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">입금일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payDate }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">취소일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ today }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">거래금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ popupData.payAmt | numberWithCommas }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul
                            class="data-content"
                            v-if="!['CREDIT', 'CREDIT_DEF','SELF'].includes(popupData.payDiv)"
                          >
                            <li class="field">
                              <div class="content bottom-check-box">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="printFlag"
                                          />
                                          <i></i>
                                          <div class="label">
                                            고객용 영수증 발행
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              class="field"
                              v-if="popupData.payDiv === 'CARD' && payCardInfo.approvalDiv === 'OK'"
                            >
                              <div class="content bottom-check-box">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="telCancel"
                                          />
                                          <i></i>
                                          <div class="label">
                                            전화 취소
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">취소사유</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        ref="cancelReason"
                                        v-model="cancelReason"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-03">
                  <div class="section-body">
                    <div class="body-text">
                      <strong>{{ popupData.visitName }}</strong
                      >님의 {{ payTitle }}금액
                      {{ popupData.payAmt | numberWithCommas }}원이 정산
                      취소처리 됩니다.<br />
                      계속하시겠습니까?
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <!-- 메시지 상태 영역 -->
          <div class="operationMessage">
            {{ message }}
          </div>
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  ref="payCancelButton"
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="payCancel"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePayCancelPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style>
body .window.payCancel .windowContent .article-section.section-01 .body-data .field {width: calc(100% + 1px);}
body .window.payCancel .windowContent .article-section.section-01 .body-data .field .content.bottom-check-box {width: 100%;}

body .operationMessage {width: calc(100% - 160px)}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  cancelPayInfo,
  getReceiptInfo,
  getTgPayCardByCardId,
} from "@/api/common";
import {
  commonCodesGetBizMstInfo,
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { getTodayNavigationDate } from "@/utils/date";
import { validateFormRefs } from "@/utils/formUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getStoreBsnMstInfo } from "@/api/bizCodeManagement";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { getDeviceInfo } from "@/utils/device";
import { openERPPay } from "@/utils/Pay";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import GolfERPService from "@/service/GolfERPService";
import { getFormattedTelNumber } from "@/utils/string";
import BillPrintUtil from "@/utils/billPrintUtil";

export default {
  name: "payCancelPopup",
  components: { InputTextarea, InputText,ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  data: function () {
    return {
      receiptInfo: {},
      printFlag: getDeviceInfo().isBillPrinterUse,
      telCancel: false,
      popupData: {},
      today: null,
      cancelReason: ".",
      payCardInfo: {},
      payCashInfo: {},
      payCashProofCancelCode: "1",
      proofCancelCodeList: commonCodesGetCommonCode("PROOF_CANCEL_CODE"),
      message: "",
      cancelProcessing: false,
      payTitle: null,
      van: null,
      proofNo: null,
      cardProof: false,
      bsnInfo: null,
      storeCode: null,
      bizMstInfo: {},
    };
  },
  computed: {
    proofKindName() {
      return commonCodesGetComName("PROOF_KIND", this.payCashInfo.proofKind);
    },
    validateRefList() {
      return {
        cancelReason: {
          required: true,
        },
      };
    },
  },
  filters: {
    numberWithCommas(value) {
      if (!value) {
        return "0";
      }

      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  async created() {
    this.today = await getTodayNavigationDate("YYYY-MM-DD");

    this.bizMstInfo = commonCodesGetBizMstInfo();

    this.bizMstInfo.bizNo =
      this.bizMstInfo.bizNo.substr(0, 3) +
      "-" +
      this.bizMstInfo.bizNo.substr(3, 2) +
      "-" +
      this.bizMstInfo.bizNo.substring(5);
  },
  methods: {
    validateFormRefs,
    commonCodesGetComName,
    async showPayCancelPopup(popupData) {
      console.log("popupData.===>", popupData);
      try {
        const { payDiv, cardId, cashId, depositUseDiv } = popupData;
        await this.initialize(payDiv, cardId, cashId, depositUseDiv);
        this.popupData = popupData;
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          this.errorToast(e.message);
        } else {
          this.errorToast(e);
        }
        this.closePayCancelPopup();
      }
    },
    closePayCancelPopup() {
      this.$emit("popupClosed", { isReload: true });
    },
    async initialize(payDiv, cardId, cashId, depositUseDiv) {
      if (payDiv === "CARD") {
        let { value: response } = await getTgPayCardByCardId(cardId);

        if (response.approvalDiv === "OK") {
          await this.setVan(response.storeCode);
        }

        this.payCardInfo = response;

        this.payTitle = "정산";
      } else if (payDiv === "EASY_PAY") {
        let { value: response } = await getTgPayCardByCardId(cardId);

        if (response.approvalDiv === "OK") {
          await this.setVan(response.storeCode);
        }

        this.payCardInfo = response;

        this.payTitle = "정산";
      } else if ((payDiv === "CASH" || payDiv === "TRANS") && cashId) {
        let cashReceipt = await GolfERPService.fetchCashReceipt(cashId);
        await this.setVan(cashReceipt.storeCode);
        this.payCashInfo = cashReceipt;
        this.cardProof = cashReceipt.cardProof;

        this.payTitle = "정산";
      } else {
        if (cashId) {
          let cashReceipt = await GolfERPService.fetchCashReceipt(cashId);
          await this.setVan(cashReceipt.storeCode);
          this.payCashInfo = cashReceipt;
          this.cardProof = cashReceipt.cardProof;
        }
        this.payTitle = commonCodesGetComName("DEPOSIT_USE_DIV", depositUseDiv);
      }
    },
    async setVan(storeCode) {
      this.storeCode = storeCode;

      const { value: bsnInfo } = await getStoreBsnMstInfo(storeCode);
      this.bsnInfo = bsnInfo;
      const { vanKind } = bsnInfo;
      this.van = vanKind;
    },
    startCancel() {
      if (this.cancelProcessing) {
        return;
      }
      this.cancelProcessing = true;

      this.$refs["payCancelPopup"].$el.nextElementSibling.style.zIndex = `${
        parseInt(
          this.$refs["payCancelPopup"].$el.nextElementSibling.style.zIndex
        ) + 1
      }`;
    },
    endCancel() {
      const itsMe = this;
      new Promise(function(resolve){
        if (!itsMe.cancelProcessing) {
          return;
        }
        itsMe.cancelProcessing = false;
        itsMe.$refs["payCancelPopup"].$el.nextElementSibling.style.zIndex = `${
          parseInt(
            itsMe.$refs["payCancelPopup"].$el.nextElementSibling.style.zIndex
          ) - 1
        }`;

        resolve();
      });
    },
    payCancel: async function () {
      try {
        this.$refs.payCancelButton.blur();
        // 정산 중일 진행 불가
        if (this.cancelProcessing) {
          return;
        }

        // 일마감 여부 확인.
        try {
          await GolfErpAPI.fetchCloseCheck({
            bsnDateFrom: this.popupData.payDate,
            bsnDateTo: this.popupData.payDate,
            storeCode: this.popupData.storeCode,
          });
        } catch (e) {
          console.error(e);
          return;
        }

        // 디밍 처리
        this.startCancel();

        if (!this.validate()) {
          this.endCancel();
          return;
        }

        if (this.telCancel) {
          if (!(await this.confirm("전화취소! 처리 하시겠습니까?"))) {
            this.endCancel();
            return;
          }
        } else {
          if (!(await this.confirm("정산을 취소하시겠습니까?"))) {
            this.endCancel();
            return;
          }
        }

        const payCancelInfo = {
          payDiv: this.popupData.payDiv,
          salesDiv: this.popupData.salesDiv,
          bsnDate: this.popupData.bsnDate,
          chkinId: this.popupData.chkinId, // 체크인ID
          teamGrpFlag: this.popupData.teamGrpFlag,
          visitId: this.popupData.visitId, // 내장객ID
          payId: this.popupData.payId, // 정산ID
          cancelReason: this.cancelReason, // 취소사유,
          storeCode: this.popupData.storeCode,
          payDate: this.popupData.payDate,
          incomId: this.popupData.incomId,
          returnId: this.popupData.returnId,
          useId: this.popupData.useId,
          depositUseDiv: this.popupData.depositUseDiv,
          isOnlyCardCancel: this.popupData.isOnlyCardCancel,
          isOnlyPayDataCancel: this.popupData.isOnlyPayDataCancel,
          isPos: this.popupData.isPos,
          telCancelFlag: this.telCancel,
          stayId: this.popupData.stayId,
        };

        if (payCancelInfo.teamGrpFlag) {
          payCancelInfo.grpNo = this.popupData.grpNo;
        }

        // 기승인 취소 플래그
        // 기승인 취소 되었을 경우 정산 완료 시 팝업 메시지가 달라져야함
        switch (this.popupData.payDiv) {
          case "CARD":
            payCancelInfo.cardId = this.popupData.cardId; // 카드ID(정산구분이 카드일경우)
            if (this.payCardInfo.approvalDiv === "OK" && !this.telCancel) {
              const payTransactionRequest = this.createPayTransactionRequestByPayCardInfo(
                this.payCardInfo
              );
              const { tid, status, message } = await this.payVan(payTransactionRequest);
              if (status === "ALREADY_CANCELED") {
                this.infoToast(
                  this.$t("vanModule.popupMessage.alreadyCanceled")
                );
              } else if (status === "SELFCANCEL") {
                this.message = message;
                this.endCancel();
                return;
              }

              payCancelInfo.tid = tid;
            }
            break;
          case "EASY_PAY":
            payCancelInfo.cardId = this.popupData.cardId; // 카드ID(정산구분이 카드일경우)
            if (this.payCardInfo.approvalDiv === "OK" && !this.telCancel) {
              const payTransactionRequest = this.createPayTransactionRequestByPayEASYInfo(this.payCardInfo);
              const { tid, status, message } = await this.payVan(payTransactionRequest);
              if (status === "ALREADY_CANCELED") {
                this.infoToast(this.$t("vanModule.popupMessage.alreadyCanceled"));
              } else if (status === "SELFCANCEL") {
                this.message = message;
                this.endCancel();
                return;
              }
              payCancelInfo.tid = tid;
            }
            break;
          case "CASH":
            break;
          case "CREDIT":
            break;
          case "DEPOSIT":
          case "PRECARD":
          case "GIFT":
          case "TICKET":
          case "POINT":
          case "SELF":
          case "GROUP_GIFT":
            break;
          case "RETURN":
          case "YEAR":
          case "ETC":
          case "PROMTN":
          case "TRANS":
          case "CREDIT_DEF":
            break;
          default:
            this.errorToast(this.$t("vanModule.popupMessage.invalidPayDiv"));
            this.endCancel();
            return;
        }

        // // 정산 취소 API 호출 및 호출 성공 시 팝업 닫기
        // try {
        //   if (this.popupData.useId) {
        //     const depositUseCancel = {
        //       salesDiv: payCancelInfo.salesDiv,
        //       payId: payCancelInfo.payId,
        //       returnId: payCancelInfo.returnId,
        //       useId: payCancelInfo.useId,
        //       cancelReason: payCancelInfo.cancelReason,
        //       useDate: payCancelInfo.bsnDate,
        //       storeCode: payCancelInfo.storeCode,
        //     };
        //     await GolfErpAPI.deleteDepositUse(depositUseCancel);
        //   } else {
        //     const { value: cancelCardId } = await cancelPayInfo(payCancelInfo);
        //
        //     if (this.popupData.payDiv === "CARD") {
        //       if (cancelCardId) {
        //         this.print(cancelCardId);
        //       } else {
        //         this.print(payCancelInfo.cardId);
        //       }
        //     }
        //   }
        // } catch (e) {
        //   return;
        // }
        if (
          (this.popupData.payDiv === "CASH" ||
            this.popupData.payDiv === "TRANS" ||
            this.popupData.payDiv === "DEPOSIT" ||
            this.popupData.payDiv === "PRECARD") &&
          this.popupData.cashId &&
          !this.popupData.isOnlyPayDataCancel
        ) {
          let tid = 0;
          let status = null;
          if (this.payCashInfo.approvalDivision === "OK") {
            const payTransactionRequest = this.createPayTransactionRequestByPayCashInfo(
              this.payCashInfo
            );
            const {tid: tidByVan, status: statusByVan, message} = await this.payVan(payTransactionRequest);

            tid = tidByVan;
            status = statusByVan;

            if (status === "SELFCANCEL") {
              this.message = message;
              this.endCancel();
              return;
            }
          }

          const cancelCashId = await GolfERPService.patchPayCashReceipt(
            this.popupData.cashId,
            tid
          );

          if (status === "ALREADY_CANCELED") {
            this.infoToast(this.$t("vanModule.popupMessage.alreadyCanceled"));
          } else {
            this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
            this.popupData.isPayCash = true;
            this.print(this.popupData.payDiv === "DEPOSIT" ? this.popupData.useId : cancelCashId);
          }

        } else {
          if (
            !['CASH','CREDIT','DEPOSIT','PRECARD','GIFT','GROUP_GIFT','CREDIT_DEF','TICKET','POINT','SELF'].includes(this.popupData.payDiv)
          ) {
            this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
          }
        }

        // 정산 취소 API 호출 및 호출 성공 시 팝업 닫기
        try {
          if (this.popupData.useId) {
            const depositUseCancel = {
              salesDiv: payCancelInfo.salesDiv,
              payId: payCancelInfo.payId,
              returnId: payCancelInfo.returnId,
              useId: payCancelInfo.useId,
              cancelReason: payCancelInfo.cancelReason,
              useDate: payCancelInfo.bsnDate,
              storeCode: payCancelInfo.storeCode,
            };
            await GolfErpAPI.deleteDepositUse(depositUseCancel);
          } else {
            const { value: cancelCardId } = await cancelPayInfo(payCancelInfo);

            if (this.popupData.payDiv === "CARD") {
              if (cancelCardId) {
                this.print(cancelCardId);
              } else {
                this.print(payCancelInfo.cardId);
              }
            } else if (['CREDIT','CREDIT_DEF'].includes(this.popupData.payDiv)) {
              this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
            } else if (this.popupData.payDiv === "EASY_PAY") {
              if (cancelCardId) {
                this.print(cancelCardId);
              } else {
                this.print(payCancelInfo.cardId);
              }
            }
          }
         if (
              this.popupData.payDiv === "DEPOSIT" ||
              this.popupData.payDiv === "PRECARD" ||
              this.popupData.payDiv === "GIFT" ||
              this.popupData.payDiv === "GROUP_GIFT" ||
              this.popupData.payDiv === "TICKET" ||
              this.popupData.payDiv === "POINT"
          ) {
            this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
            this.print(
                ["GIFT", "GROUP_GIFT","TICKET","POINT"].includes(this.popupData.payDiv) ? (this.popupData.useId ? this.popupData.useId : this.popupData.payId) : this.popupData.useId,
                null,
                this.popupData.payDiv === "GIFT" ? !!this.popupData.useId : false
            );
          } else if (this.popupData.payDiv === "CASH" && !this.popupData.cashId) {
          this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
          this.print(
              this.popupData.salesDiv === "CREDIT"
                  ? this.popupData.returnId
                  : this.popupData.salesDiv === "DEPOSIT"
                      ? this.popupData.incomId
                      : this.popupData.payId
          );
         }
        } catch (e) {
          this.endCancel();
          return;
        }

        this.$emit("popupClosed", { isReload: true });
      } catch (e) {
        if (e instanceof Error) {
          this.pinnedToast({
            message: this.$t("vanModule.popupMessage.approveCancelFail") +
              "<br><br>" +
              e.message
          });
          this.message = e.message;
        } else {
          this.pinnedToast({
            message: this.$t("vanModule.popupMessage.approveCancelFail") + "<br><br>" + e
          });
          this.message = e;
        }
      } finally {
        this.endCancel();
      }
    },
    createPayTransactionRequestByPayCardInfo(payCardInfo) {
      return {
        van: this.van,
        payDivision: "CARD",
        approvalDivision: "CANCEL",
        totalAmount: payCardInfo.approvalAmt,
        taxAmount: payCardInfo.taxAmt,
        vatAmount: payCardInfo.vatAmt,
        notaxAmount: payCardInfo.notaxAmt,
        serviceAmount: payCardInfo.svcAmt,
        divideTerm: payCardInfo.divideTerm,
        approvalNo: payCardInfo.approvalNo,
        approvalDateTime: payCardInfo.approvalDt,
        storeCode: this.storeCode,
        businessId: this.payCardInfo.bsnId,
        posNo: getDeviceInfo().posNo,
        termId: this.payCardInfo.termId,
        payDate: this.popupData.payDate,
        visitId: this.popupData.visitId
      };
    },
    createPayTransactionRequestByPayEASYInfo(payEasyInfo) {
      return {
        van: this.van,
        payDivision: "EASY_PAY",
        approvalDivision: "CANCEL",
        totalAmount: payEasyInfo.approvalAmt,
        taxAmount: payEasyInfo.taxAmt,
        vatAmount: payEasyInfo.vatAmt,
        notaxAmount: payEasyInfo.notaxAmt,
        serviceAmount: payEasyInfo.svcAmt,
        divideTerm: payEasyInfo.divideTerm,
        approvalNo: payEasyInfo.approvalNo,
        approvalDateTime: payEasyInfo.approvalDt,
        storeCode: this.storeCode,
        businessId: this.payCardInfo.bsnId,
        posNo: getDeviceInfo().posNo,
        termId: this.payCardInfo.termId,
        payDate: this.popupData.payDate,
        visitId: this.popupData.visitId
      };
    },
    createPayTransactionRequestByPayCashInfo(payCashInfo) {
      return {
        van: this.van,
        payDivision: "CASH",
        approvalDivision: "CANCEL",
        totalAmount: payCashInfo.approvalAmount,
        taxAmount: payCashInfo.taxAmount,
        vatAmount: payCashInfo.vatAmount,
        notaxAmount: payCashInfo.notaxAmount,
        serviceAmount: payCashInfo.serviceAmount,
        proofKind: payCashInfo.proofKind,
        proofNo: this.proofNo,
        cardProof: this.cardProof,
        proofCancelCode: this.payCashProofCancelCode,
        approvalNo: payCashInfo.approvalNo,
        approvalDateTime: payCashInfo.approvalDateTime,
        storeCode: this.storeCode,
        businessId: this.payCashInfo.businessId,
        posNo: getDeviceInfo().posNo,
        termId: this.payCashInfo.termId,
        payDate: this.popupData.payDate,
        visitId: this.popupData.visitId
      };
    },
    async payVan(addTransactionRequest) {
      const { tid, status, message } = await openERPPay(addTransactionRequest);
      switch (status) {
        case "CANCELED":
        case "ALREADY_CANCELED":
        case "SELFCANCEL":
        case "APPROVED":
          return { tid, status, message };
        case "READY":
        case "REQUEST":
          throw new Error("결제가 취소되었습니다.");
        case "FAIL":
        case "ROLLBACK":
          throw new Error(message);
      }
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    async getReceiptInfo(payId, payDiv, isCashCancel = false) {
      try {
        const {
          value: { receiptInfo },
        } = await getReceiptInfo(
          payId,
          payDiv,
          this.popupData.salesDiv,
          this.popupData.isPayCash,
          isCashCancel
        );

        if (
          payDiv === "CASH" ||
          payDiv === "TRANS"
        ) {
          receiptInfo.proofName = commonCodesGetComName(
            "PROOF_KIND",
            receiptInfo.proofKind
          );
        }

        this.receiptInfo = receiptInfo;
      } catch (e) {
        console.error("getReceiptInfo.err.===>", e);
        throw e;
      }
    },
    async print(id, signYN, isDepositGift) {
      try {
        if (
          !this.printFlag ||
          this.popupData.payDiv === "CREDIT" ||
          (this.popupData.payDiv === "TRANS" && !this.popupData.cashId)
        ) {
          return;
        }

        if (id === null) {
          return;
        }

        // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.
        let receiptCode =
          (this.popupData.payDiv.toUpperCase() === "PRECARD" ||
            (this.popupData.payDiv.toUpperCase() === "GIFT" && isDepositGift)
            ? "DEPOSIT"
            : (this.popupData.payDiv === "GROUP_GIFT"
                ? "GIFT"
                : this.popupData.payDiv === "EASY_PAY"
                  ? "CARD"
                  : this.popupData.payDiv
              ).toUpperCase()) + "_RECEIPT";



        let printSetData = null;

        if (
          this.popupData.payDiv.toUpperCase() === "DEPOSIT" ||
          this.popupData.payDiv.toUpperCase() === "PRECARD" ||
          (this.popupData.payDiv.toUpperCase() === "GIFT" && isDepositGift)
        ) {
          const depositUseReceipt = await GolfErpAPI.fetchDepositUseReceipt(id);

          let depositUseList = JSON.parse(
            JSON.stringify(depositUseReceipt.depositUseList)
          );

          depositUseList.forEach((data) => {
            data.depositUseDiv =
              data.depositUseDiv === undefined || data.depositUseDiv === null
                ? ""
                : commonCodesGetComName("DEPOSIT_USE_DIV", data.depositUseDiv);
          });

          let payReturnId = null;

          if (this.popupData.salesDiv === "SALES") {
            payReturnId = depositUseReceipt.payCashId;
          } else if (this.popupData.salesDiv === "CREDIT") {
            payReturnId = depositUseReceipt.returnCashId;
          }

          if (payReturnId && !this.popupData.isOnlyPayDataCancel) {
            receiptCode="DEPOSIT_CASH_RECEIPT";
            await this.getReceiptInfo(payReturnId, "CASH");
          }

          printSetData = {
            printDt: depositUseReceipt.printDt,
            cardNo:
              depositUseReceipt.cardNo === undefined ||
              depositUseReceipt.cardNo === null
                ? ""
                : depositUseReceipt.cardNo,
            depositAmt: depositUseReceipt.depositAmt,
            sumUseAmt: depositUseReceipt.sumUseAmt,
            useAmt: depositUseReceipt.useAmt,
            balanceAmt: depositUseReceipt.balanceAmt,
            totalUseAmt: depositUseReceipt.totalUseAmt,
            useDate: depositUseReceipt.useDate,
            startCourse:
              depositUseReceipt.startCourse === undefined ||
              depositUseReceipt.startCourse === null
                ? ""
                : commonCodesGetComName(
                    "COURSE_CODE",
                    depositUseReceipt.startCourse
                  ),
            startTime:
              depositUseReceipt.startTime === undefined ||
              depositUseReceipt.startTime === null
                ? ""
                : depositUseReceipt.startTime,
            depositUseDiv:
              depositUseReceipt.depositUseDiv === undefined ||
              depositUseReceipt.depositUseDiv === null
                ? ""
                : commonCodesGetComName(
                    "DEPOSIT_USE_DIV",
                    depositUseReceipt.depositUseDiv
                  ),
            ccName: this.bizMstInfo.ccName,
            bizNo: this.bizMstInfo.bizNo,
            rprsntvName: this.bizMstInfo.rprsntvName,
            telNo: getFormattedTelNumber(this.bizMstInfo.telNo, ")"),
            faxNo: getFormattedTelNumber(this.bizMstInfo.faxNo, ")"),
            depositUseList: depositUseList,
            memberName: depositUseReceipt.memberName,
            contactTel: getFormattedTelNumber(depositUseReceipt.contactTel),
            addr: depositUseReceipt?.addr,
            approvalDt: this.receiptInfo?.approvalDt,
            approvalNo: this.receiptInfo?.approvalNo,
            cashTitle:
              (this.receiptInfo?.approvalDiv === "OK"
                ? "현금승인 "
                : "현금취소 ") +
              (this.receiptInfo?.proofName
                ? "(" + this.receiptInfo?.proofName + ")"
                : "(미발급)"),
            merchantName: depositUseReceipt?.bizName,
            proofNo: this.receiptInfo?.proofNo,
            rePrintDt: null,
            totAmt: this.receiptInfo?.approvalAmt,
            taxAmt: this.receiptInfo?.taxAmt,
            vatAmt: this.receiptInfo?.vatAmt
          };
        } else {
          await this.getReceiptInfo(
            id,
            (
              this.popupData.payDiv === "GROUP_GIFT"
                ? "GIFT"
                : this.popupData.payDiv === "EASY_PAY"
                  ? "CARD"
                  : this.popupData.payDiv
            ),
            (this.popupData.cashId === null ? true : false)
          );

          printSetData = {
            storeName: this.commonCodesGetComName(
              "STORE_CODE",
              this.receiptInfo.storeCode
            ),
            bsnDate: this.receiptInfo.payDate,
            lockerNo: this.receiptInfo.lockerNo,
            slipNo: this.receiptInfo.slipNo,
            cardKind: this.receiptInfo.issuCompanyName,
            cardNo: this.receiptInfo.cardNo,
            validTerm: this.receiptInfo.validTerm,
            approvalDt: this.receiptInfo.approvalDt,
            purcName: this.receiptInfo.purcName,
            rePrintDt: null,
            totAmt: this.receiptInfo.approvalAmt,
            taxAmt: this.receiptInfo.taxAmt,
            noTaxAmt: this.receiptInfo.notaxAmt,
            svcAmt:
              this.receiptInfo.svcAmt === "0" ? null : this.receiptInfo.svcAmt,
            vatAmt: this.receiptInfo.vatAmt,
            approvalNo: this.receiptInfo.approvalNo,
            divideTerm: this.receiptInfo.divideTerm,
            merchantNo: this.receiptInfo.merchantNo,
            merchantName: this.receiptInfo.bizName,
            bizNo: this.receiptInfo.bizNo,
            rprsntvName: this.receiptInfo.rprsntvName,
            addr: this.receiptInfo.addr,
            telNo: getFormattedTelNumber(this.receiptInfo.telNo),
            faxNo: getFormattedTelNumber(this.receiptInfo.faxNo),
            proofName: this.receiptInfo.proofName,
            proofNo: this.receiptInfo.proofNo,
            cardTitle:
              this.receiptInfo.approvalDiv === "OK" ||
              this.receiptInfo.approvalDiv === "T_OK"
                ? "매출전표 (신용승인)"
                : "매출전표 (신용취소)",
            cashTitle:
              (this.receiptInfo.approvalDiv === "OK"
                ? "현금승인 "
                : "현금취소 ") +
              (this.receiptInfo.proofName
                ? "(" + this.receiptInfo.proofName + ")"
                : "(미발급)"),
            transTitle:
              (this.receiptInfo.approvalDiv === "OK"
                ? "계좌이체승인 "
                : "계좌이체취소 ") +
              (this.receiptInfo.proofName
                ? "(" + this.receiptInfo.proofName + ")"
                : "(미발급)"),
            signYn: signYN,
            giftNo: this.receiptInfo.giftNo,
            payAmt: this.receiptInfo.payAmt,
            memberName: this.receiptInfo.memberName,
            contactTel: getFormattedTelNumber(
              this.receiptInfo.contactTel
            ),
            printDt: this.receiptInfo.printDt,
            giftTitle: this.receiptInfo.approvalDiv === "OK" ? "상품권 사용 내역서" : "상품권 취소 내역서",
          };
        }
        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this, receiptCode , true, printSetData , config);


      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage += `\n[영수증 발급 에러] ${e.message}`;
        } else {
          this.operationMessage += `\n[영수증 발급 에러] ${e}`;
        }
        this.errorToast(
          this.$t("vanModule.popupMessage.printReceiptFail")
        );
      }
    },
  },
};
</script>
